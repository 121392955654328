import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'

const Sitemap = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="sitemap" lang="ja" />
        <title>{`サイトマップ | ${title}`}</title>
        <meta name="description" content="サイトマップのご案内です。" />
      </Helmet>
      <div className="common-header clearfix">
        <h1>サイトマップ</h1>
        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /<li>サイトマップ</li>
        </div>
      </div>

      <div className="main-wrapper">
        <div className="row">
          <div className="container">
            <ul className="left">
              <li>
                <Link to={`/news`}>トピックス</Link>
              </li>
              <li>
                <li>
                  <Link to={`/service`}>製品／サービス</Link>
                </li>
                <ul className="service-child">
                  <li>
                    <Link to={`/service/internet/`}>
                      ネットワーク対応精算機
                    </Link>
                  </li>
                  <li>
                    <Link to={`/service/remote/`}>遠隔管理システム</Link>
                  </li>
                  <li>
                    <Link to={`/service/lockless/`}>
                      ナンバー認識システム「ロックレス」
                    </Link>
                  </li>
                  <li>
                    <Link to={`/service/gate/`}>
                      ゲート式駐車場管理システム
                    </Link>
                  </li>
                  <li>
                    <Link to={`/service/bike/`}>バイク用ロック板ユニット</Link>
                  </li>
                  <li>
                    <Link to={`/service/unit/`}>ロック板ユニット</Link>
                  </li>
                  <li>
                    <Link to={`/service/camera/`}>防犯カメラ</Link>
                  </li>
                  <li>
                    <Link to={`/service/qtnet/`}>QT-net</Link>
                  </li>
                  <li>
                    <Link to={`/service/smartpay/`}>Smart Pay</Link>
                  </li>
                  <li>
                    <Link to={`/service/support/`}>管理サポート</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={`/whitepaper/`}>カタログダウンロード</Link>
              </li>
            </ul>

            <ul className="right">
              <li>
                <Link to={`/about/`}>会社概要</Link>
              </li>
              <li>
                <Link to={`/mvv/`}>ミッション・ビジョン・バリュー</Link>
              </li>
              <li>
                <Link to={`/faq/`}>よくあるご質問</Link>
              </li>
              <li>
                <Link to={`/contact/`}>お問い合わせ</Link>
              </li>
              <li>
                <Link to={`/sitemap/`}>サイトマップ</Link>
              </li>
              <li>
                <a href="/assets/files/privacy.pdf" target="_blank">
                  個人情報・訂正・削除依頼書
                </a>
              </li>
              <li>
                <Link to={`/policy/`}>プライバシーポリシー</Link>
              </li>
            </ul>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Sitemap
